import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.push.js";
import MarkDownPage from '@/pages/md/MarkDown.vue';
import AlertDialog from '@/components/AlertDialog.vue';
import { MESSAGE, TIPS } from './constants';
import { VideoPlay, VideoPause, Pointer, Tickets, Delete, Microphone, Message, Mute, ArrowDown, CirclePlus, ChatDotRound, Mic, CloseBold, Connection, Notification } from '@element-plus/icons-vue';
import { ElLoading, ElMessage } from 'element-plus';
import trtcClient from './trtcClient';
import handleInit from './handle-init';
import hostController from './hostController';
import memberController from './memberController';
import { nextTick } from 'vue';
let timer;
let timerCount;
export default {
  name: "LiveRoom",
  components: {
    // LivePlayer, 
    MarkDownPage,
    Pointer,
    VideoPlay,
    Tickets,
    VideoPause,
    Delete,
    Microphone,
    Mute,
    ArrowDown,
    CirclePlus,
    ChatDotRound,
    Mic,
    CloseBold,
    AlertDialog,
    Connection,
    Notification,
    Message
  },
  props: {
    aid: {
      type: Number,
      default: 0
    },
    pid: {
      type: Number,
      default: 0
    },
    hid: {
      type: Number,
      default: 0
    },
    token: {
      type: String,
      default: ''
    },
    appid: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      viewReportId: 0,
      showPerson: null,
      contactLoading: false,
      stepDes: '',
      activeName: '',
      promptName: '',
      memberListName: '女嘉宾',
      loading: null,
      loopValue: true,
      videoPlaying: false,
      messageBoxFocus: false,
      questionInputShow: false,
      customQuestionShow: false,
      questionTo: null,
      questionTab: 'first',
      question: ''
    };
  },
  watch: {
    viewReportId(val) {
      if (!val) {
        document.title = this.activity.name;
      }
    },
    imInfo: {
      handler(val) {
        if (val.step === 'waiting') {
          this.stepDes = '等待开始';
        } else if (val.step === 'starting') {
          this.stepDes = '开场白';
        } else if (val.step === 'introduction') {
          this.stepDes = '嘉宾自我介绍';
        } else if (val.step === 'truth') {
          this.stepDes = '真心话对对碰';
        } else {
          this.stepDes = '结束转化';
        }
        document.title = this.activity.name;
      },
      deep: true
    },
    published(val) {
      if (!val) {
        if (this.user.isHost) {
          this.$refs.hostLocalCamera.classList.add('live-preview');
        } else {
          this.$refs.localCamera.classList.add('live-preview');
        }
      } else {
        if (this.user.isHost) {
          this.$refs.hostLocalCamera.classList.remove('live-preview');
        } else {
          this.$refs.localCamera.classList.remove('live-preview');
        }
      }
    }
  },
  setup(props) {
    const {
      persons,
      hosts,
      user,
      imInfo,
      reportTypes,
      reports,
      activity,
      remainTime,
      confirm,
      messages,
      bgms,
      initData,
      updateStat,
      loadMoreBgms,
      getUserByRoomUserId
    } = handleInit(props);
    const {
      roomStatus,
      shareStatus,
      micStatus,
      camStatus,
      remoteUsersViews,
      remoteHostsViews,
      videoDeviceId,
      audioDeviceId,
      speakerDeviceId,
      audioMuted,
      videoMuted,
      currentBgm,
      bgmPlayed,
      videoPlayer,
      published,
      preview,
      publishCamera,
      startBeauty,
      startBgm,
      pauseBgm,
      resumeBgm,
      loopBgm,
      stopBgm,
      setBgmVolume,
      initTRTC,
      enterRoom,
      handleStartShare,
      handleStopShare,
      handleStartLocalAudio,
      handleStopLocalAudio,
      handleStartLocalVideo,
      handleStopLocalVideo,
      handleExit,
      switchDevice,
      toggleAudio,
      toggleVideo,
      sendMessage,
      sendCommand,
      detect
    } = trtcClient(user, persons, hosts, bgms, imInfo, confirm, getUserByRoomUserId);
    const {
      watchers,
      createRoom,
      launchLive,
      cancelManager,
      setManager,
      kickout,
      muteAll,
      mute,
      unmute,
      closeRoom,
      getWatcher,
      startIntroduction,
      startTruth,
      setSpeaker,
      unsetSpeaker,
      openHoldMic,
      startEnding,
      completedEnded,
      notifyStart
    } = hostController(user, activity, imInfo, hosts, sendCommand, updateStat, confirm);
    const {
      readed,
      msgInputShow,
      privateMsg,
      sendPrivateMsg,
      showRelation,
      relations,
      loadRelations,
      updateRelation,
      sendTo,
      ready,
      holdMic,
      applyContact,
      agreeContact,
      handleRelationMsg,
      retryApply,
      replyMsg
    } = memberController(user, persons, hosts, imInfo, preview, handleStartLocalAudio, handleStartLocalVideo, sendMessage, sendCommand, updateStat, getUserByRoomUserId, confirm);
    return {
      persons,
      hosts,
      user,
      imInfo,
      reportTypes,
      reports,
      activity,
      remainTime,
      confirm,
      messages,
      initData,
      updateStat,
      loadMoreBgms,
      roomStatus,
      shareStatus,
      micStatus,
      camStatus,
      remoteUsersViews,
      remoteHostsViews,
      videoDeviceId,
      audioDeviceId,
      speakerDeviceId,
      audioMuted,
      videoMuted,
      currentBgm,
      bgmPlayed,
      bgms,
      videoPlayer,
      published,
      publishCamera,
      startBeauty,
      startBgm,
      pauseBgm,
      resumeBgm,
      loopBgm,
      stopBgm,
      setBgmVolume,
      initTRTC,
      enterRoom,
      handleStartShare,
      handleStopShare,
      handleStartLocalAudio,
      handleStopLocalAudio,
      handleStartLocalVideo,
      handleStopLocalVideo,
      handleExit,
      switchDevice,
      toggleAudio,
      toggleVideo,
      sendMessage,
      sendCommand,
      detect,
      watchers,
      createRoom,
      launchLive,
      cancelManager,
      setManager,
      kickout,
      muteAll,
      mute,
      unmute,
      closeRoom,
      getWatcher,
      startIntroduction,
      startTruth,
      setSpeaker,
      unsetSpeaker,
      openHoldMic,
      startEnding,
      completedEnded,
      notifyStart,
      readed,
      preview,
      sendTo,
      msgInputShow,
      privateMsg,
      showRelation,
      relations,
      loadRelations,
      updateRelation,
      sendPrivateMsg,
      applyContact,
      agreeContact,
      ready,
      holdMic,
      retryApply,
      replyMsg,
      handleRelationMsg,
      TIPS
    };
  },
  async mounted() {
    this.loading = ElLoading.service({
      lock: true,
      text: '正在准备房间',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.installEventHandle();
    await this.initTRTC();
    const fail = await this.initData({
      aid: this.aid,
      hid: this.hid,
      pid: this.pid
    });
    if (fail) {
      this.loading.close();
      return;
    }
    if (this.user.isHost && this.user.id === this.activity.host.id) {
      if (this.activity.status === 'completed') {
        await this.createRoom(this.token, this.activity.id);
      }
    }
    await this.initRoom();
    this.loading.close();
  },
  async onUnmounted() {
    await this.handleDestroy();
  },
  computed: {
    menOnlineCount() {
      return this.persons.filter(p => p.online).length;
    },
    womenOnlineCount() {
      return this.persons.filter(p => p.online).length;
    },
    debug() {
      return window.location.host.startsWith('192.168') || window.location.host.startsWith('survey') || window.location.host.startsWith('localhost');
    }
  },
  methods: {
    messageBoxExpand() {
      this.messageBoxFocus = true;
      this.scrollToBottom();
    },
    messageBoxCollapse() {
      this.messageBoxFocus = false;
      this.scrollToBottom();
    },
    async initRoom() {
      await this.enterRoom(this.aid, this.appid, this.user.userId, this.token, true);
      if (this.user.isMember) {
        this.messages.push({
          type: 'notify',
          title: this.user.namePrefix,
          name: '你',
          color: 'green',
          content: '进入房间'
        });
      }
      if (this.user.isHost) {
        this.messages.push({
          type: 'notify',
          title: '',
          name: '你',
          color: 'green',
          content: '进入房间'
        });
      }
      if (this.imInfo.step === 'waiting') {
        if (this.user.isHost) {
          await this.handleStartLocalAudio();
        }
      } else {
        if (this.user.isHost) {
          await this.handleStartLocalAudio();
          this.confirm = {
            title: '提示',
            content: '你已上麦，是否需要先预览摄像头画面？',
            buttonConfirm: '不用了',
            showCancel: true,
            buttonCancel: '预览',
            show: true,
            callback: async confirm => {
              this.preview = !confirm;
              await this.handleStartLocalVideo({
                publish: confirm
              });
            }
          };
        }
        if (this.user.isMember && this.imInfo.speakers && this.imInfo.speakers.includes(this.user.id)) {
          await this.startBeauty();
          await this.handleStartLocalAudio();
          this.confirm = {
            title: '提示',
            content: '你已上麦，是否需要先预览摄像头画面？',
            buttonConfirm: '不用了',
            showCancel: true,
            buttonCancel: '预览',
            show: true,
            callback: async confirm => {
              this.preview = !confirm;
              await this.handleStartLocalVideo({
                publish: confirm
              });
            }
          };
        }
        if (this.user.isMember && this.imInfo.step === 'truth') {
          await this.loadRelations();
        }
      }
    },
    bgmToggle(bgm) {
      if (this.bgmPlayed && this.currentBgm.id === bgm.id) {
        this.stopBgm();
      } else {
        this.startBgm(bgm);
      }
    },
    playVideo() {
      this.videoPlaying = true;
      this.$refs.videoPlayer.play().catch(() => {
        this.videoPlaying = false;
      });
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
      this.videoPlaying = false;
    },
    showQuestion(id) {
      if (this.imInfo.questions[id]) {
        if (this.imInfo.questions[id].find(q => q.f === this.user.id)) {
          ElMessage({
            message: `你对${this.persons.find(p => p.id === id).namePrefix}已经发起过提问`,
            type: 'warning',
            offset: 200
          });
          return;
        }
      }
      this.questionTo = this.persons.find(p => p.id === id);
      this.questionInputShow = true;
    },
    openMsgInputShow() {
      this.sendTo = this.user.isMember ? this.activity.host.id : this.showPerson.id;
      this.msgInputShow = true;
      this.showPerson = null;
    },
    async handleMsg(_ref) {
      let {
        detail
      } = _ref;
      const {
        from,
        to,
        content
      } = detail;
      if (to.id === this.user.id) {
        this.makeMsgDiv(from.name, null, from.id, content);
      }
      if (from.id === this.user.id) {
        this.makeMsgDiv(null, to.name, to.id, content);
      }
    },
    makeMsgDiv(from, to, target, content) {
      this.messages.push({
        type: 'message',
        title: from ? from : '你',
        color: 'cyan',
        name: to ? to : '你',
        target,
        content
      });
      console.log(this.messages);
      this.scrollToBottom();
    },
    async handleCmd(_ref2) {
      let {
        detail
      } = _ref2;
      const {
        command,
        data
      } = detail;
      switch (command) {
        case MESSAGE.CMD_TYPE.APPLY_CONTACT:
        case MESSAGE.CMD_TYPE.AGREE_CONTACT:
        case MESSAGE.CMD_TYPE.REFUSE_CONTACT:
          await this.handleRelationMsg(command, data);
          break;
        case MESSAGE.CMD_TYPE.MUTE:
          await this.updateStat();
          if (data.id === 'all' || data.id === this.user.userId) {
            if (data.type === 'audio') {
              await this.handleStopLocalAudio();
            } else if (data.type === 'video') {
              await this.handleStopLocalVideo();
            } else if (data.type === 'all') {
              await this.handleStopLocalAudio();
              await this.handleStopLocalVideo();
            }
          }
          break;
        case MESSAGE.CMD_TYPE.UNMUTE:
          console.log('handle command -> unmute: ', data);
          await this.updateStat();
          if (data.id === 'all' || data.id === this.user.userId) {
            if (data.type === 'audio') {
              await this.handleStartLocalAudio();
            } else if (data.type === 'video') {
              this.confirm = {
                title: '提示',
                content: '你已上麦，是否需要先预览摄像头画面？',
                buttonConfirm: '不用了',
                showCancel: true,
                buttonCancel: '预览',
                show: true,
                callback: async confirm => {
                  this.preview = !confirm;
                  await this.handleStartLocalVideo({
                    publish: confirm
                  });
                }
              };
            } else if (data.type === 'all') {
              await this.handleStartLocalAudio();
              this.confirm = {
                title: '提示',
                content: '你已上麦，是否需要先预览摄像头画面？',
                buttonConfirm: '不用了',
                showCancel: true,
                buttonCancel: '预览',
                show: true,
                callback: async confirm => {
                  this.preview = !confirm;
                  await this.handleStartLocalVideo({
                    publish: confirm
                  });
                }
              };
            }
          }
          break;
        case MESSAGE.CMD_TYPE.OPEN_MIC:
          this.imInfo.canHoldMic = data.gender;
          break;
        case MESSAGE.CMD_TYPE.SET_MANAGER:
          {
            const host = await this.getWatcher(data.id, MESSAGE.USER_TYPE.HN);
            if (this.user.type === 'h' && data.id === this.user.id) {
              this.user.isHost = host.me = true;
              this.confirm = {
                title: '提示',
                content: `你被房主设为主持人${this.imInfo.step === 'waiting' ? '。' : '，是否需要先预览摄像头画面？'}`,
                buttonConfirm: this.imInfo.step !== 'waiting' ? '确认' : '不用了',
                cancelText: this.imInfo.step !== 'waiting' ? '取消' : '预览',
                showCancel: true,
                show: true,
                callback: async confirm => {
                  await this.handleStartLocalAudio();
                  if (this.activityStep !== 'waiting') {
                    this.preview = !confirm;
                    await this.handleStartLocalVideo({
                      publish: confirm
                    });
                  }
                }
              };
              await this.updateStat();
            }
            this.hosts.push(host);
            this.watchers = this.watchers.filter(watcher => watcher.id !== data.id);
          }
          break;
        case MESSAGE.CMD_TYPE.DEL_MANAGER:
          {
            const host = this.hosts.find(h => h.id === data.id);
            if (this.user.type === 'h' && data.id === this.user.id) {
              await this.handleStopLocalAudio();
              await this.handleStopLocalVideo();
              this.confirm = {
                title: '提示',
                content: '你被房主撤销了主持人，已转为观众',
                buttonConfirm: '确认',
                show: true,
                showCancel: false
              };
              this.user.isHost = false;
            }
            this.hosts = this.hosts.filter(host => host.id !== data.id);
            this.watchers.push(host);
          }
          break;
        case MESSAGE.CMD_TYPE.READY_PIDS:
          await this.updateStat();
          break;
        case MESSAGE.CMD_TYPE.QUESTIONS:
          {
            console.log('收集问题 -> ', data);
            const questions = this.imInfo.questions ? this.imInfo.questions : {};
            if (!questions[data.to]) {
              questions[data.to] = [{
                f: data.from,
                q: data.question
              }];
            } else {
              questions[data.to].push({
                f: data.from,
                q: data.question
              });
            }
          }
          break;
        case MESSAGE.CMD_TYPE.STATUS:
          //非命令发起者
          await this.updateStat();
          console.log('强制刷新 -> ', this.imInfo);
          if (this.user.isHost) {
            // 强制刷新主持人的设备开关状态
            if (this.imInfo.step === 'waiting') {
              await this.handleStartLocalAudio();
            } else {
              await this.handleStartLocalAudio();
              await this.handleStartLocalVideo({
                publish: true
              });
            }
          } else {
            if (this.imInfo.step === 'truth' && this.imInfo.speakers.length === 0) {
              if (this.micStatus === 'started') {
                await this.handleStopLocalAudio();
              }
              if (this.camStatus === 'started') {
                await this.handleStopLocalVideo();
              }
            }
          }
          break;
      }
    },
    async submitQuestion() {
      const content = this.user.isMember ? `你对${this.questionTo.namePrefix}只能发起一次提问，是否确认提交？` : '是否确认提交？';
      this.confirm = {
        title: '提示',
        content,
        buttonConfirm: '提交',
        showCancel: true,
        show: true,
        callback: async confirm => {
          if (confirm) {
            this.questionInputShow = false;
            this.customQuestionShow = false;
            const questions = this.imInfo.questions ? this.imInfo.questions : {};
            if (!questions[this.questionTo.id]) {
              questions[this.questionTo.id] = [{
                f: this.user.isHost ? -this.user.id : this.user.id,
                q: this.question
              }];
            } else {
              questions[this.questionTo.id].push({
                f: this.user.isHost ? -this.user.id : this.user.id,
                q: this.question
              });
            }
            await this.updateStat(this.imInfo);
            this.sendCommand(MESSAGE.CMD_TYPE.QUESTIONS, `${this.questionTo.id}:${this.user.isHost ? -this.user.id : this.user.id}:${this.question}`);
            ElMessage({
              message: '提交成功',
              type: 'success',
              offset: 200
            });
          }
        }
      };
    },
    delQuestion(toId, index) {
      this.confirm = {
        title: '提示',
        content: '是否确认删除？',
        buttonConfirm: '删除',
        showCancel: true,
        show: true,
        callback: async confirm => {
          if (confirm) {
            if (!toId && this.user.isMember) {
              this.showPerson.myAsk = null;
              this.imInfo.questions[this.showPerson.id] = this.imInfo.questions[this.showPerson.id].filter(item => item.f !== this.user.id);
              await this.updateStat(this.imInfo);
            } else {
              this.imInfo.questions[toId].splice(index, 1);
              await this.updateStat(this.imInfo);
            }
            this.sendCommand(MESSAGE.CMD_TYPE.STATUS, 'introduction');
            ElMessage({
              message: '删除成功',
              type: 'success',
              offset: 200
            });
          }
        }
      };
    },
    chooseQuestion(q) {
      this.question = this.question === q ? '' : q;
    },
    async handleUserEnter(_ref3) {
      let {
        detail
      } = _ref3;
      const {
        id,
        type
      } = detail;
      if (type === MESSAGE.USER_TYPE.PERSON) {
        console.log('用户进入 -> ', id, type);
        const person = this.persons.find(p => p.id === id);
        if (person) {
          // 嘉宾进入
          person.online = true;
          this.messages.push({
            type: 'notify',
            title: person.namePrefix,
            name: person.displayName,
            target: person.id,
            color: 'green',
            content: '进入房间'
          });
        } else {
          // 游客进入
          if (this.user.isHost) {
            // 仅主持人可见
            const watcher = await this.getWatcher(id, type);
            this.messages.push({
              type: 'notify',
              title: '游客',
              color: 'green',
              name: watcher.name || watcher.extra_string_1,
              content: '进入房间'
            });
          }
        }
      } else {
        let hn = this.hosts.find(h => h.id === id);
        if (hn) {
          hn.online = true;
        }
        console.log('红娘进入 -> ', hn);
        // 红娘进入   仅主持人可见
        if (this.user.isHost) {
          hn = hn ? hn : await this.getWatcher(id, type);
          this.messages.push({
            type: 'notify',
            title: '红娘',
            color: 'green',
            name: hn.name,
            content: '进入房间'
          });
        }
      }
      this.scrollToBottom();
    },
    async handleUserExit(_ref4) {
      let {
        detail
      } = _ref4;
      const {
        id,
        type
      } = detail;
      if (type === MESSAGE.USER_TYPE.PERSON) {
        const person = this.persons.find(p => p.id === id);
        if (person) {
          // 嘉宾退出
          person.online = false;
          if (this.user.isHost) {
            this.messages.push({
              type: 'notify',
              title: person.namePrefix,
              color: 'orangered',
              name: person.displayName,
              content: '离开房间'
            });
          }
        } else {
          // 游客退出
          const watcher = await this.getWatcher(id, type);
          if (this.user.isHost) {
            // 仅主持人可见
            this.messages.push({
              type: 'notify',
              title: '游客',
              color: 'orangered',
              name: watcher.name || watcher.extra_string_1,
              content: '离开房间'
            });
          }
        }
      } else {
        // 红娘
        let hn = this.hosts.find(h => h.id === id);
        if (hn) {
          hn.online = false;
        }
        if (this.user.isHost) {
          // 仅主持人可见
          hn = hn ? hn : await this.getWatcher(id, type);
          this.messages.push({
            type: 'notify',
            title: '红娘',
            color: 'orangered',
            name: hn.name,
            content: '离开房间'
          });
        }
      }
      this.scrollToBottom();
    },
    // sofaroom
    openPerson(id) {
      if (this.user.isMember) {
        if (!this.user.ready && this.imInfo.step === 'waiting') {
          this.confirm = {
            title: '提示',
            content: '请先阅读《活动声明》及《注意事项》并准备',
            buttonConfirm: '确认',
            show: true,
            showCancel: false
          };
          return;
        }
      } else if (!this.user.isHost) {
        if (this.activity.status !== 'living') {
          this.confirm = {
            title: '提示',
            content: '观众无法查看',
            buttonConfirm: '确认',
            show: true,
            showCancel: false
          };
          return;
        }
      }
      this.showPerson = this.persons.find(p => p.id === id);
      if (this.imInfo.step === 'truth') {
        this.showRelation = this.relations.find(r => r.pid === id);
      }
      if (this.imInfo.questions && this.imInfo.questions[id]) {
        this.showPerson.myAsk = this.imInfo.questions[id].find(q => q.f === this.user.id);
      }
    },
    viewReport(report) {
      let tid = report.id;
      if (!tid) {
        const s_pid = Math.min(report.s_pid, report.t_pid);
        const t_pid = Math.max(report.s_pid, report.t_pid);
        this.reports.map(t => {
          if (t.type === report.type && s_pid === t.s_pid && t_pid === t.t_pid) {
            tid = t.id;
          }
        });
      }
      this.viewReportId = String(tid);
    },
    bgmShow() {
      if (this.bgms.length === 0) {
        this.loadMoreBgms();
      }
    },
    // 滚动到底部
    scrollToBottom() {
      nextTick(() => {
        const messageContainer = this.$refs.messageContainer;
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      });
    },
    async handleDestroy() {
      console.log('退出 -> beforeunload');
      await this.handleExit();
      this.unInstallEventHandler();
      clearInterval(timer);
    },
    installEventHandle() {
      window.addEventListener('userEnter', this.handleUserEnter);
      window.addEventListener('userExit', this.handleUserExit);
      window.addEventListener('newMessage', this.handleMsg);
      window.addEventListener('newCommand', this.handleCmd);
      timer = setInterval(() => {
        timerCount++;
        if (this.imInfo.start_at) {
          let remainTime = Math.floor(60 * 60 - (Date.now() / 1000 - this.imInfo.start_at));
          let min = Math.floor(remainTime / 60);
          let sec = remainTime % 60;
          this.remainTime = `${min} 分 ${sec} 秒`;
        }
        if (timerCount % 5 === 0) {
          timerCount = Math.floor(timerCount / 5);
        }
      }, 1000);
      window.addEventListener('beforeunload', this.handleDestroy);
    },
    unInstallEventHandler() {
      window.removeEventListener('userEnter', this.handleUserEnter);
      window.removeEventListener('userExit', this.handleUserExit);
      window.removeEventListener('newMessage', this.handleMsg);
      window.removeEventListener('newCommand', this.handleCmd);
      window.removeEventListener('beforeunload', this.handleDestroy);
    }
  }
};