import Base from './base.js'

class LiveRoomService extends Base {
  constructor() {
    super('/live')
  }

  async detail(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/detail", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async initRoom(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/create", data)
        .then(res => {
          console.log('initRoom -> ', res)
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async stat(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/stat", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async watchersInfo(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/watchers", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
  
  async kickout(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/kickout", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async setM(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/setm", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async cancelM(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/cancelm", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async dismiss(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/dismiss", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async completed(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/completed", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async notifyStart(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/notifystart2p", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
}

export default new LiveRoomService()
