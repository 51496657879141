export const MESSAGE = {
    TYPE: {
        TEXT: 1,
        COMMAND: 2,
        EVENT: 3,
        NOTIFY: 4
    },
    USER_TYPE: {
        HN: 1,
        PERSON: 2
    },
    GENDER: {
        ALL: 0,
        MALE: 1,
        FEMALE: 2
    },
    CMD_TYPE: {
        MUTE: 1,
        UNMUTE: 2,
        SET_MANAGER: 3,
        DEL_MANAGER: 4,
        STATUS: 5,
        READY_PIDS: 6,
        QUESTIONS: 7,
        OPEN_MIC: 8,
        APPLY_CONTACT: 9,
        AGREE_CONTACT: 10,
        REFUSE_CONTACT: 11
    }
}

export const TIPS = {
    HOST: {
        WAITING: [
            { title: '欢迎语一', content: '欢迎x号男/女嘉宾 x美女/x帅哥 进入房间，感谢大家的耐心等待。我们将在10分钟后正式开始，咱们先进来的朋友也可以先看下你和异性朋友们的匹配报告，大致的先了解了解对方。' },
            { title: '欢迎语二', content: '各位久等了，非常欢迎各位参加今天的（活动名称）相亲活动！我们还在等待一些嘉宾入场，正式开场还有大约5分钟。感谢大家的耐心，请利用这段时间大致了解一下今天的异性嘉宾，点击咱们页面上的嘉宾头像就可以看到基础信息和你们的匹配报告！' },
            { title: '欢迎语三', content: '亲爱的朋友们，欢迎来到我们今天的线上相亲会！为了让每位嘉宾都能顺利入场，开场时间稍有延迟，预计在3分钟后开始。感谢大家的理解和耐心，请大家利用这段时间可以点击异性嘉宾头像，能有一个大致的了解！一会方便重点关注你的心仪对象。' }
        ],
        STARTING: [
            {
                title: '活动流程与时间预估',
                prefix: '尊敬的各位嘉宾，接下来我将为大家介绍本次活动的流程及大致时间安排：',
                list: [
                    { title: '自我介绍环节（大约25分钟）：', content: '每位参与者将有2-3分钟时间进行自我介绍，大家在下方也可以参考我们提供的一份自我介绍模板，里面有很多可选项。参考可选项对自己进行一个简单介绍。请大家尽量简洁明了，避免冗长的介绍，以确保每位参与者都有充分的时间展示自己。我们将在自我介绍过程中按顺序邀请大家逐一发言。' },
                    { title: '选择嘉宾问题：', content: '当上麦的嘉宾自我介绍的过程中，也是其他异性嘉宾可以向他/她提出问题的时候，我们也给到了大家一些可以参考的提问，当然也可以自定义你的问题，当然一定要保持文明和礼貌。那么收集的问题就会作为后面的真心话对对碰环节的主要内容。每人只能提一个问题哦。比如：一号男嘉宾向一号女嘉宾只能提一个问题。' },
                    { title: '真心话对对碰环节(大约25分钟)：', content: '当每位嘉宾都进行了自我介绍并收集到异性嘉宾的问题后，就进入到我们的真心话对对碰环节了。每位嘉宾针对提问进行认真作答，作答时是不知道是哪位嘉宾提的问，所以尽可能的认真作答，方便异性嘉宾更进一步的了解你。' },
                    { title: '请求互换联系方式：', content: '当你心仪的嘉宾针对你的问题作答，你就可以向他/他发起互换联系方式的请求，对方收到请求后同意互换，那么恭喜二位，你们私下就可以进行更深入的沟通了，当然也需要大家注意甄别。' }
                ]
            }, {
                title: '活动声明',
                list: [
                    { title: '活动性质：', content: '本次活动旨在为单身男女提供一个互动交流的平台，通过视频会议形式进行相亲匹配。所有参会者信息仅供本次活动使用，活动结束后会自动销毁。' },
                    { title: '参与资格：', content: '本次活动仅限已报名且符合年龄要求的单身男女参与。任何伪造身份、虚假信息的行为将被立即取消资格。' },
                    { title: '隐私保护：', content: '我们承诺对所有参与者的个人信息保密，活动期间请勿录制、截图或转发他人信息。对于泄露或不当使用他人信息的行为，活动方将保留追责权。' },
                    { title: '匹配报告：', content: '活动结束后，每位参与者将收到与异性配对的匹配报告，报告内容将包括双方的基本信息及匹配建议。报告仅供个人参考，不作为强制约定。' },
                    { title: '活动规则：', content: '本次活动采用视频会议形式，每场活动会根据人数限制，确保每位参与者都能有机会交流。在活动中，请尊重他人，保持文明和礼貌，不得发布不当言论或行为。' },
                    { title: '免责声明：', content: '活动方不对任何参与者在活动中的个人行为及匹配结果负责。参与者自愿承担相应风险，并同意尊重活动过程中的所有安排。' }
                ]
            }, {
                title: '注意事项',
                list: [
                    { title: '准时参会：', content: '请确保准时进入会议室，避免迟到影响活动进程。活动将在规定的时间内准时开始和结束，请提前做好准备。' },
                    { title: '视频和麦克风设置：', content: '为确保音视频清晰，请在进入会议前检查您的摄像头和麦克风是否正常工作。如有技术问题，请提前联系主持人帮助解决。' },
                    { title: '着装与形象：', content: '请注意仪表着装，保持良好形象。尽量选择安静、整洁的环境进行视频通话，避免出现干扰因素。' },
                    { title: '尊重他人：', content: '请尊重其他参与者的意见和隐私。在互动过程中，避免过度打扰或提出不适当的问题，做到礼貌待人。' },
                    { title: '遵守互动时间：', content: '每位参与者的互动时间有限，请在规定时间内进行自我介绍和提问，确保活动的顺利进行。' },
                    { title: '安全与合法性：', content: '本次活动严格禁止任何形式的骚扰、恶意行为以及不符合公共秩序的言论。若发现违规行为，活动方有权取消其参与资格。' },
                    { title: '活动结束后的反馈：', content: '您的意见对我们非常重要，对活动有任何建议可以随时向主持人反馈，帮助我们不断优化和提升活动质量。' }
                ]
            }
        ],
        INTRODUCTION: [

        ]
    },
    MEMBER: {
        STATEMENT: {
            prefix: '欢迎参加本次线上相亲会！为了确保活动顺利进行并为每位参与者提供最佳体验，特制定以下活动声明。请您在活动前仔细阅读，并严格遵守。',
            list: [
                { title: '活动性质：', content: '本次活动旨在为单身男女提供一个互动交流的平台，通过视频会议形式进行相亲匹配。所有参会者信息仅供本次活动使用，活动结束后会自动销毁。' },
                { title: '参与资格：', content: '本次活动仅限已报名且符合年龄要求的单身男女参与。任何伪造身份、虚假信息的行为将被立即取消资格。' },
                { title: '隐私保护：', content: '我们承诺对所有参与者的个人信息保密，活动期间请勿录制、截图或转发他人信息。对于泄露或不当使用他人信息的行为，活动方将保留追责权。' },
                { title: '匹配报告：', content: '活动结束后，每位参与者将收到与异性配对的匹配报告，报告内容将包括双方的基本信息及匹配建议。报告仅供个人参考，不作为强制约定。' },
                { title: '活动规则：', content: '本次活动采用视频会议形式，每场活动会根据人数限制，确保每位参与者都能有机会交流。在活动中，请尊重他人，保持文明和礼貌，不得发布不当言论或行为。' },
                { title: '免责声明：', content: '活动方不对任何参与者在活动中的个人行为及匹配结果负责。参与者自愿承担相应风险，并同意尊重活动过程中的所有安排。' }
            ]
        },
        INTENTION: [
            { title: '准时参会：', content: '请确保准时进入会议室，避免迟到影响活动进程。活动将在规定的时间内准时开始和结束，请提前做好准备。' },
            { title: '视频和麦克风设置：', content: '为确保音视频清晰，请在进入会议前检查您的摄像头和麦克风是否正常工作。如有技术问题，请提前联系主持人帮助解决。' },
            { title: '着装与形象：', content: '请注意仪表着装，保持良好形象。尽量选择安静、整洁的环境进行视频通话，避免出现干扰因素。' },
            { title: '尊重他人：', content: '请尊重其他参与者的意见和隐私。在互动过程中，避免过度打扰或提出不适当的问题，做到礼貌待人。' },
            { title: '遵守互动时间：', content: '每位参与者的互动时间有限，请在规定时间内进行自我介绍和提问，确保活动的顺利进行。' },
            { title: '安全与合法性：', content: '本次活动严格禁止任何形式的骚扰、恶意行为以及不符合公共秩序的言论。若发现违规行为，活动方有权取消其参与资格。' },
            { title: '活动结束后的反馈：', content: '您的意见对我们非常重要，对活动有任何建议可以随时向主持人反馈，帮助我们不断优化和提升活动质量。' }
        ],
        INTRODUCTION: {
            title: '嘉宾自我介绍模板（无必选）',
            list: [
                {
                    title: '一. 开场问候',
                    subList: [{
                        title: '', content: '大家好，我叫_______，很高兴今天能和大家见面。'
                    }]
                }, {
                    title: '二. 基本信息',
                    subList: [{
                        title: '1.年龄：', content: '我今年____岁，来自_______（城市/地区）。'
                    }, {
                        title: '2.家庭背景：', content: '简单介绍一下你的家庭，比如“我是独生子女，父母目前在_______”或“家里还有一个姐姐/弟弟”。'
                    }]
                }, {
                    title: '三. 职业与生活状态',
                    subList: [{
                        title: '1.职业：', content: '我目前的工作是_______，从事这份工作已经有_______年了。我的日常工作主要包括_______（简要描述）。'
                    }, {
                        title: '2.职业感悟：', content: '我非常喜欢/享受这份工作，因为_______（例如：“能够带来成就感”、“让我不断挑战自己”）。'
                    }, {
                        title: '3.生活状态：', content: '目前我的生活节奏是_______（描述一下工作与生活的平衡，或是否经常出差等）。'
                    }]
                }, {
                    title: '四. 兴趣爱好与日常活动',
                    subList: [{
                        title: '1.兴趣爱好：', content: '我的爱好包括_______，特别喜欢_______，因为_______（简要描述原因，如“让我放松”、“帮助我认识新朋友”）。'
                    }, {
                        title: '2.日常活动：', content: '周末或空闲时间，我喜欢_______，例如_______（详细一点，如“和朋友一起去徒步”、“自己研究美食”）。'
                    }]
                }, {
                    title: '五. 性格特点与优点',
                    subList: [{
                        title: '1.个性描述：', content: '朋友们都形容我是_______（例如：“非常有耐心”、“爱开玩笑”、“很有责任感”），我自己也觉得_______（可以描述一些自己认同的优点）。'
                    }, {
                        title: '2.人际关系：', content: '我喜欢和_______（描述你喜欢的社交方式或朋友类型）在一起，彼此分享有趣的事物。'
                    }]
                }, {
                    title: '六. 人生观与目标',
                    subList: [{
                        title: '1.人生观：', content: '对我来说，_______（比如“家庭”或“事业”）是很重要的。我相信_______（表达你的人生信条或态度，如“努力会带来好运”）。'
                    }, {
                        title: '2.未来计划：', content: '未来几年，我希望_______（谈一谈你对职业、生活或成长的期望，如“实现个人职业目标”、“环游世界”）。'
                    }]
                }, {
                    title: '七. 对理想伴侣的描述',
                    subList: [{
                        title: '理想伴侣：', content: '我希望我的另一半是一个_______（用2-3个词描述特质，比如“积极向上”、“有责任心”、“爱运动”）的人。我们可以一起_______（说一下你想和伴侣做的事情，如“探索新的餐厅”或“一起健身”）。'
                    }]
                }, {
                    title: '八. 结尾',
                    subList: [{
                        title: '', content: '比如：希望今天能有机会和大家多交流，期待认识志同道合的朋友！'
                    }]
                },
            ]
        },
        QUESTIONS: {
            MAN: [
                {
                    title: "三观",
                    list: [
                        "你觉得自己人生中最重要的目标是什么？如何为此努力？",
                        "如果你有机会改变过去的某个决定，你会选择改变什么？为什么？",
                        "你如何看待工作和家庭之间的平衡？你觉得这两者中哪个对你来说更重要？",
                        "在你的人生中，什么样的价值观最为重要？",
                        "你如何定义人生的成功？是事业成就、家庭幸福，还是其他什么？",
                        "如果你不需要工作，你最想去做些什么？",
                        "你觉得自己的未来有什么特别的规划或者目标吗？",
                        "你怎么看待感情中的信任和支持？这对你来说有多重要？",
                        "如果你有一个梦想，现在就可以实现，你最想做什么？",
                        "你对失败的看法是什么？失败后你会如何调整自己？"
                    ]
                }, {
                    title: "日常",
                    list: [
                        "你平时喜欢做什么消磨时间？",
                        "你最喜欢的电影是什么？为什么喜欢它？",
                        "如果你能去任何一个国家旅行，你最想去哪里？",
                        "你平时有什么特别的爱好或者兴趣吗？",
                        "你喜欢做什么来放松自己？",
                        "你的周末一般是怎么度过的？",
                        "你喜欢看什么类型的影视剧或者综艺节目？",
                        "你觉得理想的周末是什么样的？",
                        "你有没有一个特别喜欢的地方？如果有，你能告诉我为什么吗？",
                        "你有没有什么一直想做但还没有实现的事情？"
                    ]
                }
            ],
            WOMAN: [
                {
                    title: "三观", 
                    list: [
                        "你对成功的定义是什么？你认为自己离成功还有多远？",
                        "在你的人生中，有没有一个特别想实现的目标？你现在在为它努力吗？",
                        "你怎么看待家庭和事业的关系？你觉得在两者之间，如何找到平衡点？",
                        "你觉得自己人生中最重要的价值观是什么？",
                        "如果你能改变人生中的一件事，你会选择改变什么？为什么？",
                        "你觉得在感情中，最重要的品质是什么？",
                        "你最看重伴侣的哪些特质？是性格、才华还是其他方面？",
                        "你有过什么困难或者挫折，是如何克服的？这些经历对你有什么影响？",
                        "你如何看待生活中的失败？如果遇到失败，你一般如何调整心态？",
                        "你未来有什么大计划或目标？你如何为这些目标做准备？"
                    ]
                }, {
                    title: "日常",
                    list: [
                        "你平时喜欢做什么休闲活动？",
                        "你最喜欢的电影或者电视剧是什么？为什么喜欢？",
                        "如果有机会去旅行，你最想去哪里？",
                        "你通常是怎样度过周末的？喜欢在家里休息还是出去活动？",
                        "你有没有一个特别喜欢的运动或者健身方式？",
                        "你最喜欢的食物是什么？有什么特别的原因吗？",
                        "你平时有哪些兴趣爱好？是如何培养这些爱好的？",
                        "你觉得理想的度假是什么样的？是放松还是充实的活动？",
                        "你喜欢的书籍类型是什么？有没有一本书是对你影响很大的？",
                        "你平时喜欢的休闲方式是什么？是看书、看电影还是做其他事情？"
                    ]
                }
            ]
        }
    }
}