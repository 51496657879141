import { ref } from 'vue';
import liveService from '@/module/liveroom';
import { MESSAGE } from './constants'
import { ElMessage } from 'element-plus'
import wx from 'weixin-js-sdk';

export default function (user, activity, imInfo, hosts, sendCommand, updateStat, confirm) {
    // 观众列表
    const watchers = ref([])

    /** h-1 p-3 */
    const getWatcher = async (id, type) => {
        console.log('getWatcher -> ', id, type)
        const watcher = watchers.value.find(w => w.id === id)
        if (!watcher) {
            const res = await liveService.watchersInfo({ ids: [`${type === MESSAGE.USER_TYPE.HN ? 'h' : 'p'}-${id}`] })
            if (res[0]) {
                watchers.value.push(res[0])
                return res[0]
            }
        }
        return watcher
    }

    /** 创建房间 */
    const createRoom = async (token, roomId) => {
        const res = await liveService.initRoom({ token, aid: roomId, roomId })
        console.log('初始化房间 -> ', res)
        if (res.err) {
            confirm.value = {
                title: '提示',
                content: res.err,
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
        } else {
            imInfo.value.step = 'waiting'
            activity.value.status = 'living'
        }
    }

    /** 指定嘉宾上麦 */
    const setSpeaker = async (id) => {
        const speakers = imInfo.value.speakers ? imInfo.value.speakers : []
        if (speakers.length >= 2) {
            confirm.value = {
                title: '提示',
                content: '同时最多只能两位嘉宾上麦',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
            }
            return
        }
        if (!speakers.includes(id)) {
            speakers.push(id)
            await updateStat({ ...imInfo.value, speakers, canHoldMic: -1 })
        }

        console.log('指定嘉宾上麦 -> ', id)
        sendCommand(MESSAGE.CMD_TYPE.UNMUTE, `all:p-${id}`)
    }

    /** 指定嘉宾下麦 */
    const unsetSpeaker = async (id) => {
        const speakers = imInfo.value.speakers.filter(s => s !== id)
        await updateStat({ ...imInfo.value, speakers })

        console.log('指定嘉宾闭麦 -> ', id)
        sendCommand(MESSAGE.CMD_TYPE.MUTE, `all:p-${id}`)
    }

    const openHoldMic = async (gender) => {
        await updateStat({ ...imInfo.value, canHoldMic: gender })

        console.log('开启嘉宾抢麦 -> 性别: ', gender)
        sendCommand(MESSAGE.CMD_TYPE.OPEN_MIC, gender)
    }

    const launchLive = async () => {
        const start_at = Math.floor(Date.now() / 1000)
        await updateStat({
            ...imInfo.value,
            step: 'starting',
            start_at
        })

        console.log('正式开始 -> ', imInfo.value)
        sendCommand(MESSAGE.CMD_TYPE.STATUS, 'starting')
    }

    const startIntroduction = async () => {
        await updateStat({
            ...imInfo.value,
            step: 'introduction',
            speakers: []
        })

        console.log('开启自我介绍环节 -> ', imInfo.value)
        sendCommand(MESSAGE.CMD_TYPE.STATUS, 'introduction')
    }

    const startTruth = async () => {
        await updateStat({
            ...imInfo.value,
            speakers: [],
            step: 'truth'
        })

        console.log('开启真心话环节 -> ', imInfo.value)
        sendCommand(MESSAGE.CMD_TYPE.STATUS, 'truth')
    }

    const startEnding = async () => {
        await updateStat({
            ...imInfo.value,
            speakers: [],
            step: 'ending'
        })

        console.log('开启结束语环节 -> ', imInfo.value)
        sendCommand(MESSAGE.CMD_TYPE.STATUS, 'ending')
    }

    const completedEnded = async () => {
        await updateStat({
            ...imInfo.value,
            speakers: [],
            step: 'ended'
        })
        console.log('活动收官 -> ', imInfo.value)
        completed()
    }

    const cancelManager = async (id) => {
        const result = await liveService.cancelM({
            aid: activity.value.id,
            hid: user.value.id, mid: id
        })
        if (result) {
            const host = hosts.value.find(h => h.id === id)
            hosts.value = hosts.value.filter(host => host.id !== id)
            watchers.value.push(host)
            sendCommand(MESSAGE.CMD_TYPE.DEL_MANAGER, id)
        }
    }

    const setManager = async (id) => {
        const result = await liveService.setM({
            aid: activity.value.id,
            hid: user.value.id, mid: id
        })
        if (result) {
            const watcher = await getWatcher(id, MESSAGE.USER_TYPE.HN)
            watchers.value = watchers.value.filter(w => w.id !== id)
            hosts.value.push(watcher)
            sendCommand(MESSAGE.CMD_TYPE.SET_MANAGER, id)
        }
    }

    const kickout = async (pid) => {
        const result = await liveService.kickout({
            aid: activity.value.id,
            hid: user.value.id, pid
        })
        console.log('kickout -> ', result)
    }

    const muteAll = () => {
        sendCommand(MESSAGE.CMD_TYPE.MUTE, {
            id: 'all',
            type: 'all'
        })
    }

    const mute = (userId, type) => {
        sendCommand(MESSAGE.CMD_TYPE.MUTE, {
            id: userId, type
        })
    }

    const unmute = (userId, type) => {
        sendCommand(MESSAGE.CMD_TYPE.UNMUTE, {
            id: userId, type
        })
    }

    const closeRoom = () => {
        confirm.value = {
            title: '提示',
            content: '是否确认关闭房间？',
            buttonConfirm: '确认',
            showCancel: true,
            show: true,
            callback: async (confirm) => {
                if (confirm) {
                    const result = await liveService.dismiss({ aid: activity.value.id, hid: user.value.id })
                    console.log('closeRoom -> ', result)
                }
            }
        }
    }

    const completed = () => {
        confirm.value = {
            title: '提示',
            content: '是否确认结束本场活动？',
            buttonConfirm: '确认',
            showCancel: true,
            show: true,
            callback: async (confirm) => {
                if (confirm) {
                    const result = await liveService.completed({ aid: activity.value.id, hid: user.value.id })
                    console.log('completed -> ', result)
                }
            }
        }
    }

    const notifyStart = async (pid, type) => {
        if (type === 'sms') {
            const date = new Date()
            if (date.getHours() > 22 || date.getHours() < 8) {
                confirm.value = {
                    title: '提示',
                    content: '由于营销类短信限制，请在早上8点以后和晚上22点以前执行此操作。',
                    buttonConfirm: '确认',
                    showCancel: false,
                    show: true,
                }
                return
            }
        }
        const result = await liveService.notifyStart({ aid: activity.value.id, pid, type })
        console.log('notifyStart -> ', result)
        if (result) {
            ElMessage({
                message: '已发送',
                type: 'success',
                offset: 200
            })
        }
    }

    return {
        watchers,
        createRoom,
        launchLive,
        cancelManager,
        setManager,
        kickout,
        muteAll,
        mute,
        unmute,
        closeRoom,
        getWatcher,
        startIntroduction,
        startTruth,
        setSpeaker,
        unsetSpeaker,
        openHoldMic,
        startEnding,
        completedEnded,
        notifyStart
    }
}