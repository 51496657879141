import { ref } from 'vue';
import wx from 'weixin-js-sdk';
import liveService from '@/module/liveroom';
import { MESSAGE } from './constants';

/**
 * 初始化房间数据
 * @returns 
 */
export default function () {
    // 活动嘉宾列表
    const persons = ref([])
    // 活动主持人列表
    const hosts = ref([])
    // 活动基础信息
    const activity = ref({})
    // 活动即时信息
    const imInfo = ref({
        speakers: [],
    })
    // 当前用户
    const user = ref({})
    // 嘉宾报告列表
    const reports = ref([])
    // 嘉宾报告类型
    const reportTypes = ref([true, true])
    // 房间剩余时间
    const remainTime = ref('')
    // 弹窗对话框
    const confirm = ref({
        show: false,
        title: '提示'
    })
    // 消息列表
    const messages = ref([])
    // 背景音乐列表
    const bgms = ref([])

    const initData = async ({ aid, pid, hid }) => {
        if (checkBefore({ aid, pid, hid })) {
            return true
        }
        const details = await liveService.detail({ aid, pid, hid })
        console.log('活动详情 -> ', details)
        activity.value = details.activity
        user.value = details.user
        reportTypes.value = details.activity.report_types
        reports.value = details.tasks
        persons.value = details.persons.map(p => {
            p.namePrefix = `${p.index + 1}号${p.gender === 1 ? '男' : '女'}嘉宾`
            p.online = p.me = user.value.type === 'p' && user.value.id === p.id
            p.displayName = p.me ? '我' : (p.name || p.extra_string_1)
            p.videoMuted = p.audioMuted = true
            return p
        })
        hosts.value = details.hosts.map(h => {
            h.online = h.me = user.value.type === 'h' && user.value.id === h.id
            h.displayName = h.me ? '我' : h.name
            h.namePrefix = '主持人'
            h.videoMuted = h.audioMuted = true
            return h
        })

        if (checkAfter()) {
            return true
        }

        await updateStat()

        return false
    }

    const loadMoreBgms = () => {
        const size = 8
        const pageIndex = bgms.value.length / size
        for (let i = 0; i < size; i++) {
            const id = pageIndex * size + i + 1
            if (id > 32) {
                break
            }
            bgms.value.push({ id, url: require(`@/assets/bgms/${id}.mp3`) })
        }
    }

    const checkAfter = () => {
        if (activity.value.status === 'ended') {
            confirm.value = {
                title: '提示',
                content: '活动已结束',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            console.log('活动已结束')
            return true
        }
        if (user.value.id !== activity.value.host.id && activity.value.status !== 'living') {
            confirm.value = {
                title: '提示',
                content: '活动房间还未开放，请联系活动主理人确认',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            console.log('活动房间还未开放，请联系活动主理人确认')
            return true
        }
        return false
    }

    const checkBefore = (data) => {
        if (!data.hid && !data.pid) {
            confirm.value = {
                title: '提示',
                content: '没有权限',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            return true
        }
        return false
    }

    const updateStat = async (stat) => {
        const extras = await liveService.stat({ aid: activity.value.id, extras: stat })
        imInfo.value.step = extras ? extras.step : 'waiting'
        if (imInfo.value.step === 'waiting') {
            imInfo.value.readyPids = extras?.readyPids ? extras.readyPids.map(p => parseInt(p)) : []
            persons.value.map(p => {
                p.ready = imInfo.value.readyPids.includes(p.id)
                if (p.me) {
                    user.value.ready = p.ready
                }
            })
        } else {
            imInfo.value.canHoldMic = extras && extras.canHoldMic !== undefined ? extras.canHoldMic : -1
            imInfo.value.speakers = extras?.speakers ? extras.speakers : []
            imInfo.value.questions = extras?.questions ? extras.questions : {}
            user.value.isSpeaker = imInfo.value.speakers.includes(user.value.id)
        }
        try {
            imInfo.value.start_at = extras?.start_at ? parseInt(extras.start_at) : 0
        } catch (e) {
            imInfo.value.start_at = 0
        }
        console.log('获取房间即时信息 -> ', imInfo.value)
        return
    }

    const getUserByRoomUserId = (userId) => {
        const elems = userId.split('-')
        if (elems[0] === 'p') {
            const person = persons.value.find(p => p.id === parseInt(elems[1]))
            return {
                id: person.id,
                type: MESSAGE.USER_TYPE.PERSON,
                namePrefix: person.namePrefix,
                name: person.displayName
            }
        } else if (elems[0] === 'h') {
            const host = hosts.value.find(h => h.id === parseInt(elems[1]))
            return {
                id: host.id,
                type: MESSAGE.USER_TYPE.HOST,
                namePrefix: '主持人',
                name: host.name
            }
        }
    }

    return {
        persons,
        hosts,
        user,
        imInfo,
        reportTypes,
        reports,
        activity,
        remainTime,
        messages,
        confirm,
        bgms,
        initData,
        updateStat,
        loadMoreBgms,
        getUserByRoomUserId
    }
}