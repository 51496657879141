export default {
  name: 'AlertDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    title: {
      type: String,
      default: '提示'
    },
    content: {
      type: String,
      default: ''
    },
    buttonConfirm: {
      type: String,
      default: '确定'
    },
    buttonCancel: {
      type: String,
      default: '取消'
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showNoMore: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  data: () => {
    return {
      noMoreTips: false
    };
  },
  methods: {
    confirm() {
      this.callback(true, this.noMoreTips);
      this.visible = false;
    },
    cancel() {
      this.callback(false, this.noMoreTips);
      this.visible = false;
    }
  }
};