import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.push.js";
import cytoscape from 'cytoscape';
import AlertDialog from '@/components/AlertDialog.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import matchService from '@/module/match';
import MarkDownPage from '@/pages/md/MarkDown.vue';
import wx from 'weixin-js-sdk';
import { ElLoading } from 'element-plus';
import { Search, CaretRight, FullScreen } from '@element-plus/icons-vue';
const delayPromise = duration => new Promise(resolve => setTimeout(resolve, duration));
const scale = window.innerWidth / 960;
const layoutPadding = 10 * scale;
const animationDuration = 500;
const easing = 'ease';
export default {
  name: 'MatchScreen',
  components: {
    MarkDownPage,
    Search,
    CaretRight,
    FullScreen,
    SwiperSlide,
    Swiper,
    AlertDialog
  },
  props: {
    tid: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      cy: null,
      fontSize: 16,
      lastHighlighted: null,
      hn: null,
      infoNode: null,
      showPerson: null,
      matchPersons: [],
      hasHighlight: false,
      highlightInProgress: false,
      originalPoisitons: {},
      viewReportId: 0,
      lastSubHighligth: null,
      confirmShow: false,
      confirm: {},
      tasks: [],
      loading: null,
      taskLoading: false,
      relationColor: 'white',
      relation: null,
      countOptions: [2, 6, 10, 16, 32],
      pageOptions: ['<<', '<', 1, 2, 3, '>', '>>'],
      page: 1,
      count: 2,
      onlyVip: true,
      total: 1000,
      searchWord: '',
      searchBtn: '搜索',
      animations: [],
      animating: false,
      animateProcess: [0, 0],
      scrollbar: null,
      fullScreen: false,
      fromMini: false
    };
  },
  async mounted() {
    this.fontSize = window.innerWidth / 1440 * 16;
    function checkEnv(ctx) {
      return new Promise((resolve, reject) => {
        let needLogin = true;
        if (typeof wx !== 'undefined' && wx.miniProgram) {
          wx.miniProgram.getEnv(res => {
            if (res.miniprogram) {
              needLogin = false;
              ctx.fromMini = true;
            }
          });
        }
        const host = window.location.host;
        // 本地测试以及开发环境不做限制
        if (host.startsWith('192.168') || host.startsWith('survey')) {
          needLogin = false;
        }
        needLogin = false;
        setTimeout(() => {
          if (needLogin) {
            reject({
              needLogin
            });
          } else {
            resolve(true);
          }
        }, 100);
      });
    }
    document.title = 'AI牵红绳会员匹配系统';
    try {
      await checkEnv(this);
      await this.init();
      window.addEventListener('resize', async resize => {
        console.log('resize -> ', resize);
        this.animating = false;
        this.initLayout = false;
        this.resetIdleTimer();
        await this.init();
      });
    } catch (err) {
      if (err.needLogin) {
        this.confirm = {
          title: '提示',
          content: '请在AI牵红绳小程序中打开',
          buttonConfirm: '确认',
          showCancel: false,
          callback: () => window.location.replace('https://www.ylaiai.com')
        };
        this.confirmShow = true;
      } else {
        this.confirm = {
          title: '提示',
          content: err,
          buttonConfirm: '确认',
          showCancel: false,
          callback: () => {
            if (wx?.miniProgram) {
              console.log(wx.miniProgram);
              wx.miniProgram.navigateBack();
            } else {
              window.location.replace('https://www.ylaiai.com');
            }
          }
        };
        this.confirmShow = true;
      }
    }

    // 监听用户操作事件，例如鼠标移动和键盘输入
    document.addEventListener('keydown', () => this.resetIdleTimer());

    // 初始化定时器
    this.resetIdleTimer();
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    }
  },
  watch: {
    animating(newValue) {
      console.log('animating -> ', newValue);
    },
    async count() {
      this.resetIdleTimer();
      this.initLayout = false;
      await this.init();
    },
    async page() {
      this.resetIdleTimer();
      this.initLayout = false;
      await this.init();
    },
    async onlyVip() {
      this.resetIdleTimer();
      this.initLayout = false;
      await this.init();
    }
  },
  methods: {
    miniNaviBack() {
      wx.miniProgram.navigateBack();
    },
    requestFullscreen() {
      try {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.fullScreen = true;
      } catch (e) {
        console.log(e);
      }
    },
    resetIdleTimer() {
      this.animating = false;
      clearTimeout(this.idleTimer);
      if (!this.viewReportId) {
        // 看报告时不启动动画
        this.startIdleTimer();
      }
    },
    startIdleTimer() {
      this.idleTimer = setTimeout(() => {
        if (!this.viewReportId) {
          // 看报告时不启动动画
          this.play();
        }
      }, 60000 * 10);
    },
    scrollToBottom(scrollbar) {
      return new Promise(resolve => {
        try {
          const scrollContainer = scrollbar.$el.querySelector('.el-scrollbar__wrap');
          const scale = window.innerWidth / 1920;
          const scrollStep = () => {
            if (!this.animating) {
              resolve();
            }
            const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            const scrollTop = scrollContainer.scrollTop;
            const distance = Math.floor(maxScrollTop - scrollTop);
            if (distance > 0) {
              scrollContainer.scrollTop += scale < 1 ? 1 : scale; // 控制滚动速度
              requestAnimationFrame(scrollStep);
            } else {
              resolve(); // 滚动结束时执行 resolve
            }
          };
          requestAnimationFrame(scrollStep);
        } catch (e) {
          console.warn(e);
          resolve();
        }
      });
    },
    async play() {
      const animations = [];
      const delayAnim = () => new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.animating) resolve();else reject('动画被终止');
        }, 2000);
      });
      if (!this.persons || this.persons.length === 0) {
        return;
      }
      for (let i = 0; i < this.persons.length; i++) {
        const personAnims = [];
        const person = this.persons[i];
        const node = this.cy.nodes().filter(n => parseInt(n.data('id')) === person.id)[0];
        if (!node) continue;
        personAnims.push(async () => {
          this.unhighlight();
          await delayAnim();
          console.log('根元素高亮 -> ', node.data('name'));
          this.highlight(node);
          await delayAnim();
        });
        const pids = [];
        this.lines.map(line => {
          if (line.s_pid === person.id) {
            pids.push(line.t_pid);
          } else if (line.t_pid === person.id) {
            pids.push(line.s_pid);
          }
        });
        const matchNodes = [];
        this.matchPersons = this.persons.filter(item => pids.includes(item.id));
        this.cy.nodes().map(n => {
          const pid = parseInt(n.data('id'));
          if (pids.includes(pid)) {
            matchNodes.push(n);
          }
        });
        console.log(matchNodes);
        for (let j = 0; j < matchNodes.length; j++) {
          const n = matchNodes[j];
          const viewReport = async () => {
            for (let k = 0; k < this.tasks.length; k++) {
              const task = this.tasks[k];
              if (!task || task.status !== 'completed') {
                await delayAnim();
              } else {
                console.log('查看报告 -> ', task.id);
                this.viewReportId = task.id;
                await delayAnim();
                console.log('报告滚动 -> ', task.id);
                const scrollbar = this.$refs['scrollbar'];
                await this.scrollToBottom(scrollbar);
                await delayAnim();
                console.log('报告关闭 -> ', task.id);
                this.viewReportId = 0;
                await delayAnim();
              }
            }
          };
          if (j > 0) {
            personAnims.push(async () => {
              await delayAnim();
              console.log('子元素高亮 -> ', n.data('name'));
              this.subHighlight(n, true);
              await delayAnim();
              console.log('查看报告 -> ', this.tasks);
              await viewReport();
            });
          } else {
            personAnims.push(async () => {
              console.log('子元素高亮 -> ', n.data('name'));
              await delayAnim();
              console.log('查看报告 -> ', this.tasks);
              await viewReport();
            });
          }
        }
        animations.push(personAnims);
      }
      this.animating = true;
      // 依次执行所有动画
      for (let i = 0; i < animations.length; i++) {
        const personAnims = animations[i];
        this.animateProcess[0] = i;
        for (let j = 0; j < personAnims.length; j++) {
          if (!this.animating) {
            break;
          }
          this.animateProcess[1] = j;
          console.log('当前动画索引 -> ', this.animateProcess);
          try {
            await personAnims[j]();
          } catch (e) {
            console.log(e);
          }
        }
        if (!this.animating) {
          break;
        }
        this.unhighlight();
      }
      this.resetIdleTimer();
    },
    async searchMatch() {
      this.resetIdleTimer();
      if (this.searchBtn === '重置') {
        this.searchWord = '';
        this.searchBtn = '搜索';
        await this.init();
      } else {
        if (this.searchWord) {
          this.searchBtn = '重置';
          await this.init(this.searchWord.split(','));
        }
      }
    },
    pageChange(page) {
      this.resetIdleTimer();
      if (page === '<') {
        if (this.page > 1) {
          this.page--;
        }
      } else if (page === '>') {
        if (this.page < this.total) {
          this.page++;
        }
      } else if (page === '>>') {
        this.page += 3;
      } else if (page === '<<') {
        this.page = this.page <= 3 ? 1 : this.page - 3;
      } else {
        this.page = page;
      }
      if (this.page <= 2) {
        this.pageOptions = ['<<', '<', 1, 2, 3, '>', '>>'];
      } else {
        this.pageOptions = ['<<', '<', this.page - 1, this.page, this.page + 1, '>', '>>'];
      }
    },
    matchChange(e) {
      this.cy.nodes().map(n => {
        if (n.data('gender') === this.showPerson.gender) {
          return;
        }
        if (n.data('index') === e.activeIndex) {
          this.subHighlight(n);
        }
      });
    },
    setSwiper(swiper) {
      this.swiper = swiper;
    },
    viewReport(id) {
      this.resetIdleTimer();
      this.viewReportId = id;
    },
    backDetail() {
      this.resetIdleTimer();
      this.viewReportId = 0;
    },
    async loadTasks(s_pid, t_pid) {
      this.taskLoading = true;
      const {
        tasks,
        relation
      } = await matchService.getMatchReports({
        s_pid,
        t_pid
      });
      this.tasks = tasks;
      this.relation = relation;
      this.taskLoading = false;
    },
    subHighlight(node, slide) {
      const nid = parseInt(node.data('id'));
      if (!this.showPerson || nid === this.showPerson?.id) {
        return;
      }
      if (this.lastSubHighligth && nid === parseInt(this.lastSubHighligth.data('id'))) {
        return;
      }
      this.viewReportId = 0;
      this.loadTasks(nid, this.showPerson.id);
      if (this.lastSubHighligth) {
        this.lastSubHighligth.data('weight', 0.3);
      }
      this.lastSubHighligth = node;
      node.data('weight', 1);
      this.cy.nodes().map(n => {
        if (n.data('id') === node.data('id') || parseInt(n.data('id')) === this.showPerson.id) {
          n.data('weight', 1);
        } else {
          n.data('weight', 0.3);
        }
      });
      this.cy.edges().map(e => {
        if ((e.data('s_pid') === this.showPerson.id || e.data('t_pid') === this.showPerson.id) && (e.data('s_pid') === nid || e.data('t_pid') === nid)) {
          e.data('weight', 1);
          this.relationColor = e.data('line');
        } else {
          e.data('weight', 0.3);
        }
      });
      if (slide) {
        this.swiper.slideTo(node.data('index'));
      }
    },
    unSubHighlight() {
      if (this.lastSubHighligth) {
        this.lastSubHighligth.data('weight', 0.3);
        this.lastSubHighligth = null;
      }
      this.viewReportId = 0;
    },
    highlight(node, sub) {
      if (this.showPerson && this.showPerson.id === parseInt(node.data('id'))) {
        return Promise.resolve();
      }
      if (this.highlightInProgress) {
        return Promise.resolve();
      }
      this.highlightInProgress = true;
      this.hasHighlight = true;
      const showPersonPanel = () => {
        const nid = parseInt(node.data('id'));
        // 初始化嘉宾面板数据
        this.persons.map(man => {
          if (man.id === nid) {
            this.showPerson = man;
          }
        });
        const pids = [];
        this.cy.edges().map(ele => {
          const s_pid = ele.data('s_pid');
          const t_pid = ele.data('t_pid');
          if (nid === s_pid || t_pid === nid) {
            pids.push(s_pid === nid ? t_pid : s_pid);
          }
        });
        this.matchPersons = this.persons.filter(item => pids.includes(item.id));
        let i = 0,
          focus;
        this.cy.nodes().map(n => {
          const pid = parseInt(n.data('id'));
          if (pids.includes(pid)) {
            n.data('index', i);
            if (!sub) {
              if (i === 0) focus = n;
            } else if (parseInt(sub.data('id')) === pid) {
              focus = n;
            }
            i++;
          } else {
            n.data('index', -1);
          }
        });
        setTimeout(() => this.subHighlight(focus, !!sub), animationDuration / 2);
      };
      const allEles = this.cy.elements();
      const nhood = this.lastHighlighted = node.closedNeighborhood();
      const others = this.lastUnhighlighted = allEles.not(nhood);
      const showOverview = () => {
        this.showPerson = null;
        this.matchPersons = [];
        this.cy.batch(() => {
          allEles.removeClass('hidden');
          others.addClass('hidden');
          others.positions(n => {
            if (n.isNode()) {
              const pos = this.originalPoisitons[n.data('id')];
              return pos ? {
                ...pos
              } : {};
            } else {
              return {};
            }
          });
        });
        const layout = nhood.layout({
          name: 'preset',
          positions: n => {
            if (n?.isNode()) {
              const position = n.data('orgPos');
              return position ? {
                x: position.x,
                y: position.y
              } : {};
            } else {
              return {};
            }
          },
          fit: true,
          animate: true,
          animationDuration: animationDuration / 2,
          animationEasing: easing,
          padding: layoutPadding
        });
        layout.run();
        return layout.promiseOn('layoutstop');
      };
      const runLayout = () => {
        const layout = nhood.layout({
          name: 'cose',
          directed: true,
          roots: [node.data('id')],
          // 指定根节点
          avoidOverlap: true,
          // 避免节点重叠
          nodeDimensionsIncludeLabels: true // 节点尺寸包括标签
        });
        const promise = layout.promiseOn('layoutstop');
        layout.run();
        return promise;
      };
      return Promise.resolve().then(showOverview).then(() => delayPromise(animationDuration)).then(showPersonPanel).then(runLayout).then(() => {
        this.highlightInProgress = false;
      }).catch(console.warn);
    },
    backMain() {
      this.resetIdleTimer();
      this.unhighlight();
    },
    unhighlight() {
      if (!this.hasHighlight) {
        return Promise.resolve();
      }
      const allEles = this.cy.elements();
      const allNodes = this.cy.nodes();
      this.cy.stop();
      allNodes.stop();
      this.showPerson = null;
      this.matchPersons = [];
      this.unSubHighlight();
      const nhood = this.lastHighlighted;
      const others = this.lastUnhighlighted;
      this.cy.nodes().map(n => n.data('weight', 0.5));
      this.cy.edges().map(n => n.data('weight', n.data('intention') ? 1 : 0.3));
      this.lastHighlighted = this.lastUnhighlighted = null;
      this.hasHighlight = false;
      const hideOthers = function () {
        others.addClass('hidden');
        return Promise.resolve();
      };
      const resetClasses = () => {
        this.cy.batch(function () {
          allEles.removeClass('hidden');
        });
        return Promise.resolve();
      };
      const animateToOrgPos = nhood => {
        return Promise.all(nhood.nodes().map(n => {
          return n.animation({
            position: this.originalPoisitons[n.data('id')],
            duration: animationDuration,
            easing: easing
          }).play().promise();
        }));
      };
      const restorePositions = () => {
        this.cy.batch(() => {
          others.nodes().positions(n => {
            return {
              ...n.data('orgPos')
            };
          });
        });
        return animateToOrgPos(nhood);
      };
      const animateToInitialView = () => {
        return this.cy.animate({
          fit: {
            eles: this.cy.elements(),
            padding: layoutPadding
          },
          pan: this.initialCenter,
          zoom: this.initialZoom,
          duration: this.animationDuration,
          easing: this.easing
        });
      };
      return Promise.resolve().then(hideOthers).then(restorePositions).then(resetClasses).then(animateToInitialView);
    },
    convertMember(persons) {
      const nodes = [];
      const scale = window.innerWidth / 960;
      const size = 80 * scale,
        fontSize = 18 * scale;
      for (let i = 0; i < persons.length; i++) {
        if (persons[i].gender === 2) {
          // femaleCount--
          nodes.push({
            data: {
              id: persons[i].id,
              name: persons[i].name,
              age: persons[i].age,
              bg: 'rgb(156, 100, 167)',
              color: 'white',
              border: persons[i].main ? 'rgb(254, 206, 13)' : 'rgb(156, 100, 167)',
              width: size + 'px',
              height: size + 'px',
              fontSize: fontSize + 'px',
              borderSize: size / 20 + 'px',
              weight: 0.5,
              gender: 2
            }
          });
        } else {
          // maleCount--
          nodes.push({
            data: {
              id: persons[i].id,
              name: persons[i].name,
              age: persons[i].age,
              bg: 'rgb(98, 190, 157)',
              color: 'white',
              border: persons[i].main ? 'rgb(254, 206, 13)' : 'rgb(98, 190, 157)',
              width: size + 'px',
              height: size + 'px',
              fontSize: fontSize + 'px',
              borderSize: size / 20 + 'px',
              weight: 0.5,
              gender: 1
            }
          });
        }
      }
      return nodes;
    },
    convertRelation(lines) {
      const scale = window.innerWidth / 960;
      const width = 3 * scale,
        fontSize = 12 * scale;
      const edge = [];
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const lineColor = line.intention ? 'rgb(250, 81 ,81)' : line.type === 'active' ? 'cyan' : '#fff';
        const s_arrow = line.intention ? 'triangle' : line.type === 'active' ? 'none' : 'triangle';
        const t_arrow = line.intention ? 'triangle' : line.type === 'active' ? 'triangle' : 'none';
        edge.push({
          data: {
            id: Math.min(line.s_pid, line.t_pid) + '-' + Math.max(line.s_pid, line.t_pid),
            name: line.intention ? '双向匹配' : '',
            s_pid: line.s_pid,
            t_pid: line.t_pid,
            source: line.s_pid,
            target: line.t_pid,
            s_arrow,
            t_arrow,
            width,
            fontSize: fontSize + 'px',
            intention: line.intention,
            weight: line.intention ? 1 : 0.3,
            line: lineColor,
            color: 'white',
            style: 'solid'
          }
        });
      }
      return edge;
    },
    async init(pids) {
      this.loading = ElLoading.service({
        lock: true,
        text: '正在准备数据',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        if (pids && pids.length > 0) {
          this.onlyVip = false;
        }
        const res = pids && pids.length > 0 ? await matchService.search({
          pids
        }) : await matchService.list({
          tid: this.tid,
          page: this.page - 1,
          count: this.count,
          onlyVip: this.onlyVip
        });
        this.persons = res.persons;
        console.log(this.persons);
        this.lines = res.lines;
        const nodes = this.convertMember(this.persons);
        const lines = this.convertRelation(this.lines);
        this.cy = cytoscape({
          container: document.getElementById('cy'),
          elements: [...nodes, ...lines],
          style: [{
            selector: 'node',
            style: {
              'label': 'data(name)',
              'background-color': 'data(bg)',
              'text-valign': 'center',
              'text-halign': 'center',
              'color': 'data(color)',
              'font-size': 'data(fontSize)',
              'opacity': 'data(weight)',
              'border-width': 'data(borderSize)',
              'border-color': 'data(border)',
              'width': 'data(width)',
              'height': 'data(height)',
              'font-weight': 'bold'
            }
          },
          // 边样式
          {
            selector: 'edge',
            style: {
              'label': 'data(name)',
              'line-color': 'data(line)',
              'line-style': 'data(style)',
              'line-opacity': 'data(weight)',
              'text-opacity': 'data(weight)',
              'curve-style': 'straight-triangle',
              'color': 'data(color)',
              'font-size': 'data(fontSize)',
              'text-rotation': 'autorotate',
              'source-arrow-color': 'data(line)',
              'source-arrow-shape': 'data(s_arrow)',
              'target-arrow-color': 'data(line)',
              'target-arrow-shape': 'data(t_arrow)',
              'width': 'data(width)',
              'text-background-opacity': 1,
              // Set background opacity
              'text-background-color': 'data(line)',
              // Set background color
              'text-background-shape': 'roundrectangle' // Set background shape
            }
          }, {
            selector: '.hidden',
            style: {
              'display': 'none'
            }
          }],
          layout: {
            name: 'grid',
            animate: true,
            avoidOverlap: true,
            spacingFactor: 1.3,
            animationDuration: animationDuration,
            animationEasing: easing,
            padding: layoutPadding
          },
          selectionType: 'single',
          maxZoom: 5,
          minZoom: 0.1,
          userPanningEnabled: true,
          boxSelectionEnabled: false
        });
        this.cy.ready(() => {
          if (this.initLayout) {
            return;
          }
          this.initLayout = true;
          // 在这里可以执行布局完成后的操作
          this.cy.nodes().forEach(node => {
            this.originalPoisitons[node.data('id')] = {
              ...node.position()
            };
            node.data('orgPos', node.position());
          });
        });
        // 监听空白处点击事件
        this.cy.on('tap', evt => {
          this.resetIdleTimer();
          if (this.highlightInProgress) {
            return;
          }
          if (evt.target.isNode && evt.target.isNode()) {
            if (!this.showPerson) {
              this.highlight(evt.target);
            } else {
              this.subHighlight(evt.target, true);
            }
          } else if (evt.target.isEdge && evt.target.isEdge()) {
            const {
              s_pid,
              t_pid
            } = evt.target.data();
            let s_node, t_node;
            this.cy.nodes().map(n => {
              if (s_pid === parseInt(n.data('id'))) {
                s_node = n;
              } else if (t_pid === parseInt(n.data('id'))) {
                t_node = n;
              }
            });
            this.highlight(s_node, t_node);
          } else {
            // 执行点击空白处的逻辑
            this.unhighlight();
          }
        });
      } finally {
        this.loading.close();
        if (window.innerHeight > window.innerWidth) {
          this.confirm = {
            title: '提示',
            content: '完整功能请切换至横屏',
            buttonConfirm: '确认',
            showCancel: false
          };
          this.confirmShow = true;
        }
      }
    }
  }
};