import { ref } from 'vue';
import { MESSAGE } from './constants'
import relationService from '@/module/relation'

export default function (user, persons, hosts, imInfo, preview, handleStartLocalAudio,
    handleStartLocalVideo, sendMessage, sendCommand, updateStat,
    getUserByRoomUserId, confirm) {

    // 是否已读声明
    const readed = ref('no')
    // 是否显示发私信输入框
    const msgInputShow = ref(false)
    // 私信内容
    const privateMsg = ref('')
    // 发送给谁
    const sendTo = ref(0)
    // 当前选中嘉宾关系
    const showRelation = ref(null)
    // 与异性嘉宾的关系
    const relations = ref([])

    const handleRelationMsg = async (type, data) => {
        console.log('处理联系方式消息 -> cmd type: ', type)
        if (data.who !== user.value.id) {
            return
        }
        await loadRelations()
        if (showRelation.value) {
            showRelation.value = relations.value.find(r => r.id === showRelation.value.id)
        }
        if (type === MESSAGE.CMD_TYPE.APPLY_CONTACT) {
            // loadRelations 中已经处理
            return
        }

        const person = persons.value.find(p => p.id === data.who)

        let confirmContent = ''
        if (type === MESSAGE.CMD_TYPE.AGREE_CONTACT) {
            confirmContent = `${person.namePrefix}[${person.displayName
            }] 同意了你的联系方式互换申请，活动结束后即可查看`

        } else if (type === MESSAGE.CMD_TYPE.REFUSE_CONTACT) {
            confirmContent = `${person.namePrefix}[${person.displayName}]拒绝了你的联系方式互换申请`
        }

        confirm.value = {
            title: '提示',
            content: confirmContent,
            buttonConfirm: '确认',
            show: true,
            showCancel: false
        }
    }

    const loadRelations = async () => {
        const pids = persons.value.filter(p => p.gender !== user.value.gender).map(p => p.id)
        const results = await relationService.findRelations({ pid: user.value.id, pids })
        relations.value = pids.map((pid, i) => {
            return { ...results[i], pid }
        })
        console.log('与异性嘉宾的关系 -> ', relations.value)
        for (const r of relations.value) {
            await processApply(r.pid, r)
        }
    }

    const updateRelation = async (pid, updated) => {
        relations.value.map((r, i) => {
            if (r.pid === pid) {
                relations.value[i] = { pid, ...updated }
            }
        })
    }

    const processApply = (pid, relation) => {
        return new Promise((resolve) => {
            const promptRelationType = user.value.gender === 1 ? 'female_apply' : 'male_apply'
            if (relation.type === promptRelationType) {
                console.log('处理互换申请 -> ', relation.type, promptRelationType)
                const person = persons.value.find(p => p.id === pid)
                confirm.value = {
                    title: '提示',
                    content: `${person.namePrefix}[${person.displayName}]申请与你互换联系方式，是否同意？`,
                    buttonConfirm: '同意',
                    showCancel: true,
                    buttonCancel: '拒绝',
                    show: true,
                    callback: async (confirm) => {
                        if (confirm) {
                            await agreeContact(pid)
                        } else {
                            await refuseApply(pid)
                        }
                        resolve()
                    }
                }
            } else {
                resolve()
            }
        })
    }

    // 拒绝申请交换
    const refuseApply = async (who) => {
        const relation = relations.value.find(r => r.pid === who)
        const result = await relationService.refuse({
            rid: relation.id,
            from: user.value.id
        })
        await updateRelation(who, result)
        console.log('拒绝申请 -> ', who)
        sendCommand(MESSAGE.CMD_TYPE.REFUSE_CONTACT, `${user.value.id}:${who}`)
    }

    // 重试申请
    const retryApply = async (who) => {
        const relation = relations.value.find(r => r.pid === who)
        const refuseGender = relation.type === 'male_refuse' ? 1 : 2
        confirm.value = {
            title: '提示',
            content: refuseGender === user.value.gender ? '申请交换联系方式' : '再次申请交换联系方式',
            buttonConfirm: '确认',
            showCancel: true,
            show: true,
            callback: (confirm) => confirm && applyContact(who)
        }
    }

    // 申请互换联系方式
    const applyContact = async (who) => {
        const result = await relationService.apply({
            target: who,
            from: user.value.id
        })
        await updateRelation(who, result)
        console.log('申请互换联系方式 -> ', result)
        sendCommand(MESSAGE.CMD_TYPE.APPLY_CONTACT, `${user.value.id}:${who}`)
    }

    // 同意互换联系方式
    const agreeContact = async (who) => {
        const relation = relations.value.find(r => r.pid === who)
        const result = await relationService.agree({
            rid: relation.id,
            from: user.value.id
        })
        await updateRelation(who, result)
        console.log('同意互换联系方式 -> ', result)
        sendCommand(MESSAGE.CMD_TYPE.AGREE_CONTACT, `${user.value.id}:${who}`)
    }

    const replyMsg = async (target) => {
        if (!target)    return
        if (user.value.isMember) {
            const host = hosts.value.find(h => h.id === target)
            if (!host) {
                return
            }
        } else {
            const person = persons.value.find(p => p.id === target)
            if (!person) {
                return
            }
        }
        sendTo.value = target
        privateMsg.value = ''
        msgInputShow.value = true
    }

    const sendPrivateMsg = async () => {
        if (!privateMsg.value || privateMsg.value.trim() === '') {
            return
        }
        if (sendTo.value === 0) {
            msgInputShow.value = true
            return
        }
        msgInputShow.value = false
        const from = user.value.userId
        const to = user.value.isMember ? 'h-' + sendTo.value : 'p-' + sendTo.value
        sendMessage(from, to, privateMsg.value)
        window.dispatchEvent(new CustomEvent('newMessage', {
            detail: {
                from: getUserByRoomUserId(from),
                to: getUserByRoomUserId(to),
                content: privateMsg.value
            }
        }))
        privateMsg.value = ''
    }

    const ready = async () => {
        await updateStat({
            ...imInfo.value,
            readyPids: imInfo.value.readyPids.concat(user.value.id)
        })

        sendCommand(MESSAGE.CMD_TYPE.READY_PIDS, imInfo.value.readyPids.join(','))
    }

    const holdMic = async () => {
        if (imInfo.value.canHoldMic === -1) {
            return
        } else if (imInfo.value.canHoldMic !== 0 && imInfo.value.canHoldMic !== user.value.gender) {
            return
        } else if (imInfo.value.speakers.includes(user.value.id)) {
            return
        } else {
            imInfo.value.speakers.push(user.value.id)
            await updateStat({ ...imInfo.value, canHoldMic: -1 })
            sendCommand(MESSAGE.CMD_TYPE.UNMUTE, `all:p-${user.value.id}`)

            await handleStartLocalAudio()
            confirm.value = {
                title: '提示',
                content: '你已上麦，是否需要先预览摄像头画面？',
                buttonConfirm: '不用了',
                showCancel: true,
                buttonCancel: '预览',
                show: true,
                callback: async (confirm) => {
                    preview.value = !confirm
                    await handleStartLocalVideo({ publish: confirm })
                }
            }
        }
    }

    return {
        readed,
        preview,
        msgInputShow,
        privateMsg,
        sendTo,
        showRelation,
        relations,
        retryApply,
        loadRelations,
        updateRelation,
        applyContact,
        agreeContact,
        refuseApply,
        sendPrivateMsg,
        handleRelationMsg,
        ready,
        replyMsg,
        holdMic
    }
}