import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-660744e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "display": "flex",
    "width": "100%",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $options.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.visible = $event),
    title: $props.title,
    "align-center": "",
    "close-on-click-modal": false,
    width: `${!$options.landscape ? '80%' : 600}`,
    "show-close": false
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.showCancel ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      onClick: $options.cancel
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.buttonCancel), 1)]),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
      class: "confirm",
      onClick: $options.confirm,
      color: "#191447"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.buttonConfirm), 1)]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.content) + " ", 1), $props.showNoMore ? (_openBlock(), _createBlock(_component_el_checkbox, {
      key: 0,
      label: "不再提醒",
      value: _ctx.noMoreTips,
      style: {
        "margin-top": "2rem"
      },
      color: "#191447"
    }, null, 8, ["value"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "title", "width"]);
}